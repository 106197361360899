import React, { useState, useEffect } from 'react';
import { Title } from '@shared/ui';
/* eslint-disable react/jsx-no-duplicate-props */

import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, DatePicker, Alert, InputNumber, Radio, Switch } from 'antd';
import { GetCategoriesList } from '@features/manage-materialcategories/model/services/GetCategoriesList';
import UploadPicturesWall from '@widgets/PicturesWall/UploadPicturesWall';
import {
   MinusCircleOutlined,
   PlusOutlined,
   CheckCircleOutlined
} from '@ant-design/icons';
import { Typography } from 'antd';
import SelectAddress from '@widgets/FormItems/SelectAddress';
import { TimePicker, Modal } from 'antd';
import { leadExtraFieldsToExport } from '@shared/const/leadExtraFields';
import { message, theme } from 'antd';
import { mobileNumberRequired } from '@shared/config/fieldValidatorSettings';
import { getTypeOfAddresses } from '@shared/api/all/const';
import dayjs from 'dayjs';
import { normalizeLeadTimes } from '@shared/lib/leadTimes';

import { UpdateLead } from '@features/list-lead/model/UpdateLead';
import { CreatePublicLead } from '@features/list-lead/model/CreatePublicLead';
const { TextArea } = Input;
const { Text } = Typography;
// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
   // Can not select days before today and today s
   return current && current < dayjs().startOf('day');
};
const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const CreateLeadPublic = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   const [form] = Form.useForm();
   const [categories, setCategories] = useState([]);
   const [priceObj, setPriceObj] = useState({
      maxPrice: 0,
      text: '',
      textType: 'info'
   });

   const [typeOfAddresses, setTypeOfAddresses] = useState([]);
   // eslint-disable-next-line no-unused-vars

   const { token } = theme.useToken();

   const contentStyle = {
      padding: '20px',
      color: token.colorTextTertiary,
      backgroundColor: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: `1px dashed ${token.colorBorder}`,
      marginTop: 16
   };

   useEffect(() => {
      fetchTypeOfAddresses();
   }, []);

   const fetchTypeOfAddresses = async () => {
      getTypeOfAddresses().then((res) => {
         setTypeOfAddresses(res?.json?.data || []);
      });
   };

   useEffect(() => {
      GetCategoriesList({
         page: 1,
         limit: 1000,
         sort: 'priority',
         order: 'asc',
         status: 'active'
      }).then((res) => {
         setCategories(res.data);
      });
   }, []);

   const loadSuccessModal = () => {
      Modal.success({
         title: 'Заявка отправлена',
         content: 'В ближайшее время ею займутся наши менеджеры'
      });
   };

   const setImagesIds = (arr) => {
      form.setFieldValue('images', arr);
   };

   const UploadImageToCreatedLead = (leadId, callBack) => {
      const handleOk = () => {
         // Отправляем обновленные идентификаторы изображений в UpdateLead
         if (!window.arr) {
            message.error('Картинки не загружены');
            return;
         }

         if (window.arr) {
            UpdateLead({ images: window.arr || [] }, leadId);
            window.arr = undefined;
         }
      };

      const setImagesIds = (arr) => {
         window.arr = arr;
      };

      Modal.confirm({
         title: `Заявка создана успешно!`,
         okText: 'Загрузить фото',
         cancelText: 'Закрыть',
         closable: true,
         maskClosable: true,
         icon: <CheckCircleOutlined style={{ color: 'green', fontSize: '36px' }} />,
         content: (
            <div>
               Номер вашей заявки: № {leadId} <br />В ближайшее время ею займутся
               наши менеджеры <br />
               Можете также загрузить фото
               <br /> <br />
               <Form.Item name="images" label="">
                  <UploadPicturesWall
                     maxCount={10}
                     images={[]}
                     setImagesIds={setImagesIds}
                  />
               </Form.Item>
            </div>
         ),
         onOk: handleOk,
         afterClose: callBack,
         onCancel() {}
      });
   };

   const createLead = (values) => {
      if (window.cantSubmitBecauseImageUploadingNow) {
         message.error('Картинки грузятся, подождите');
         return;
      }

      if (typeof values?.price === 'string') {
         if (!values.price.includes('.')) {
            values.price = values.price + '.00';
         } else {
            values.price = parseFloat(values.price).toFixed(1);
         }
      }

      if (values?.leadTimes?.length > 0) {
         const normalized = normalizeLeadTimes(values?.leadTimes, { leadTimes: [] });
         values.leadTimes = normalized;
      } else {
         message.error('Заполните даты вызова');
         return;
      }

      if (values.status) {
         values.status = {
            status: values.status || 'active',
            comment: values?.statusComment || ''
         };
      }

      setIsLoading(true);

      CreatePublicLead({ leadData: values, images: values?.images || [] })
         .then((res) => {
            UploadImageToCreatedLead(res?.id, () => {
               form.resetFields();
               setErrorMessage('');
               setPriceObj({
                  maxPrice: 0,
                  text: '',
                  textType: 'info'
               });
            });
         })
         .catch((e) => {
            const errMessage = e?.message || 'Возникла ошибка при сохранении';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setIsLoading(false));
   };

   const onFinish = (values) => {
      createLead(values);
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const suffixSelector = <Form.Item noStyle>кг</Form.Item>;
   const suffixSelectorPrice = <Form.Item noStyle>руб</Form.Item>;

   const modifiedInitialValues = {
      price: '0,0',
      priceType: 'unit',
      statusComment: '',
      leadTimes: [],
      finishDate: null,
      distance: 0
   };

   useEffect(() => {
      if (form.getFieldValue('isFree') === true) {
         form.setFieldsValue({ price: '0.0' });
      }
   }, [form.getFieldValue('isFree')]);

   function getMaxPrice(data, capacity, loading) {
      // На беке calculatePrice

      const arr = data?.capacityPriceRules;
      const defaultPrice = data.capacityPrice;

      const range = arr.find(
         (item) => capacity >= item.capacityFrom && capacity <= item.capacityTo
      );

      if (range) {
         const percentOfPrice = loading
            ? range.percentOfPriceWithLoading
            : range.percentOfPriceWithoutLoading;
         const price = defaultPrice * (percentOfPrice / 100) * capacity;

         return price.toFixed(1);
      } else {
         return '100000.0';
      }
   }

   function getPriceObj(weight, categories, categoryId) {
      const category = categories.find((e) => e.id === categoryId);
      const rules = category?.capacityRules;
      let price = null;
      let maxPrice = 1000000;
      let textType = 'info';

      if (!category?.capacityRules) {
         return {
            maxPrice: 1000000,
            text: '',

            textType: 'info'
         };
      }

      if (!weight) {
         return {
            maxPrice: 0,
            text: '',

            textType: 'info'
         };
      }

      const weightDouble = parseInt(weight);
      let text = '';

      for (const rule of rules) {
         let isWithinRange =
            !rule.capacityTo || weightDouble <= parseFloat(rule.capacityTo);
         isWithinRange =
            isWithinRange &&
            (!rule.capacityFrom || weightDouble >= parseFloat(rule.capacityFrom));

         if (isWithinRange) {
            switch (rule.priceType) {
               case 'free':
                  text = 'Бесплатный вывоз';
                  maxPrice = 0;

                  textType = 'info';

                  break;
               case 'export':
                  text = `Внимание! Вывоз такого объема вторсырья платный. Менеджер пример вашу заявку по телефону +7 936 003 91 41`;
                  maxPrice = 0;

                  textType = 'error';

                  break;
               case 'ransom':
                  if (weightDouble < 200) {
                     text = 'Заказ может быть вывезен от 1 до 3 дней.';
                     maxPrice = 10000000;

                     textType = 'info';
                  }
                  if (form.getFieldValue('isLoadingRequired') === undefined) {
                     text = 'Уточните вопрос погрузки';
                     maxPrice = 10000000;

                     textType = 'error';
                  } else {
                     text = '';

                     maxPrice = getMaxPrice(
                        category,
                        weightDouble,
                        form.getFieldValue('isLoadingRequired')
                     );
                  }
                  break;
            }
            break;
         }
      }

      return {
         maxPrice,
         text,
         textType
      };
   }

   useEffect(() => {
      if (
         form.getFieldValue('capacity') >= 0 &&
         form.getFieldValue('materialCategoryId')
      ) {
         const priceObj = getPriceObj(
            form.getFieldValue('capacity'),
            categories,
            form.getFieldValue('materialCategoryId')
         );

         // if (priceObj.price) {
         //    form.setFieldsValue({ price: priceObj.price });
         // }

         // if (priceObj.maxPrice === 0) {
         //    form.setFieldsValue({ isFree: true });
         // }

         setPriceObj(priceObj);
      }
   }, [
      form.getFieldValue('materialCategoryId'),
      form.getFieldValue('isFree'),
      form.getFieldValue('capacity'),
      form.getFieldValue('isLoadingRequired')
   ]);

   const isPriceHigh =
      parseInt(priceObj.maxPrice) > 0 &&
      parseInt(priceObj.maxPrice) <= parseInt(form.getFieldValue('price'));

   return (
      <div style={{ margin: '20px', maxWidth: '1600px' }}>
         {' '}
         <Title>Создать заявку</Title>
         <Row gutter={24}>
            {' '}
            <Col xs={24} span={24} xl={18} xs={24}>
               {' '}
               <div style={contentStyle}>
                  <div>
                     <Form
                        style={{
                           maxWidth: '100%',
                           minWidth: 320
                        }}
                        form={form}
                        initialValues={modifiedInitialValues}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        hideRequiredMark
                        layout="vertical">
                        {() => (
                           <div>
                              <Alert
                                 message="Обращаем ваше внимание, что на каждый вид сырья необходимо разместить новую заявку"
                                 type="info"
                                 showIcon
                                 style={{ marginBottom: '15px' }}
                              />

                              <Row gutter={24}>
                                 <Col span={12} xl={24} xs={24}>
                                    <Form.Item
                                       name="materialCategoryId"
                                       label="Категория вторсырья"
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Выберите категории'
                                          }
                                       ]}>
                                       <Select
                                          allowClear
                                          style={{
                                             width: '100%'
                                          }}
                                          showSearch
                                          optionFilterProp="name"
                                          filterOption={(input, option) =>
                                             (option?.name ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                          }
                                          placeholder="Выберите тип сырься"
                                          rules={[
                                             {
                                                required: true,
                                                message: 'Поле не может быть пустым'
                                             }
                                          ]}
                                          fieldNames={{
                                             label: 'name',
                                             value: 'id'
                                          }}
                                          options={categories}
                                       />
                                    </Form.Item>
                                 </Col>

                                 <Col xs={24} span={24} xl={24}>
                                    <Form.Item
                                       label={'Нужна ли погрузка'}
                                       name={'isLoadingRequired'}
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Поле пропущено'
                                          }
                                       ]}
                                       key={'isLoadingRequiredform'}>
                                       <Radio.Group name={'isLoadingRequired'}>
                                          <Radio value={true}>Да</Radio>
                                          <Radio value={false}>Нет</Radio>
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>

                                 {form.getFieldValue('isLoadingRequired') ===
                                    undefined ||
                                 form.getFieldValue('materialCategoryId') ===
                                    undefined ? null : (
                                    <Col xs={24} span={24} xl={24}>
                                       <Form.Item name="capacity" label="Вес">
                                          <Input
                                             addonAfter={suffixSelector}
                                             style={{
                                                width: '100%'
                                             }}
                                             type="number"
                                             placeholder="Введите вес"
                                          />
                                       </Form.Item>
                                       {priceObj?.text ? (
                                          <Alert
                                             showIcon
                                             style={{ marginBottom: '10px' }}
                                             message={priceObj?.text}
                                             type={priceObj?.textType || 'info'}
                                          />
                                       ) : null}
                                    </Col>
                                 )}
                              </Row>

                              <Row gutter={16}>
                                 <Col xs={24} span={24} xl={24}>
                                    <Row gutter={24}>
                                       {' '}
                                       {priceObj.maxPrice === 0 ? null : (
                                          <Col xs={24} span={24} xl={24}>
                                             <Form.Item
                                                name="isFree"
                                                label="Отдать бесплатно">
                                                <Switch
                                                   defaultChecked={form.getFieldValue(
                                                      'isFree'
                                                   )}
                                                />
                                             </Form.Item>
                                          </Col>
                                       )}
                                       {priceObj.maxPrice === 0 ? null : (
                                          <Col xs={24} span={24} xl={24}>
                                             <Form.Item
                                                name="price"
                                                label="Цена выкупа">
                                                <InputNumber
                                                   addonAfter={suffixSelectorPrice}
                                                   style={{
                                                      width: '100%'
                                                   }}
                                                   disabled={
                                                      form.getFieldValue(
                                                         'isFree'
                                                      ) === true
                                                   }
                                                   defaultValue="0.0"
                                                   type="number"
                                                   placeholder="Введите стоимость"
                                                   min="0"
                                                   step="0.1"
                                                   stringMode
                                                   precision={1}
                                                   decimalSeparator="."
                                                />
                                             </Form.Item>

                                             <div
                                                style={{
                                                   marginTop: '-10px',
                                                   marginBottom: '15px'
                                                }}>
                                                {form.getFieldValue('price') ===
                                                null ? (
                                                   <div>
                                                      <Text type="danger">
                                                         Укажите цену, либо выберите
                                                         отдать бесплатно
                                                      </Text>{' '}
                                                   </div>
                                                ) : null}
                                                {isPriceHigh ? (
                                                   <div>
                                                      <Text type="danger">
                                                         Цена слишком высокая!
                                                         Укажите меньше цену
                                                      </Text>{' '}
                                                   </div>
                                                ) : null}
                                                <div>
                                                   <Text type="secondary">
                                                      * Цена заявки может быть иной,
                                                      так как реальная оценка
                                                      происходит на месте.
                                                   </Text>
                                                </div>
                                                <div>
                                                   <Text strong>
                                                      * Расчет осуществляется по
                                                      факту вывоза вторичного сырья,
                                                      с водителем.
                                                   </Text>
                                                </div>
                                             </div>
                                          </Col>
                                       )}
                                    </Row>
                                 </Col>
                              </Row>
                              {form.getFieldValue('price') !== null &&
                              !isPriceHigh &&
                              form.getFieldValue('capacity') &&
                              priceObj.textType !== 'error' &&
                              form.getFieldValue('materialCategoryId') &&
                              form.getFieldValue('isLoadingRequired') !==
                                 undefined ? (
                                 <>
                                    <Row gutter={24}>
                                       <Col xs={24} span={24} xl={24}>
                                          <Form.Item
                                             name="comment"
                                             label="Комментарий">
                                             <TextArea
                                                rows={2}
                                                placeholder="Можете указать, номер подъезда, домофон, переулок"
                                                maxLength={500}
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name={'typeOfAddress'}
                                             label="Место вывоза"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Укажите место вывоза'
                                                }
                                             ]}>
                                             <Select
                                                style={{
                                                   width: '100%'
                                                }}
                                                maxCount={1}
                                                placeholder="Выберите место вывоза"
                                                options={typeOfAddresses.map(
                                                   (e) => ({
                                                      value: e.value,
                                                      label: e.title
                                                   })
                                                )}
                                             />
                                          </Form.Item>

                                          <Form.Item
                                             label="Полный адрес (город, улица, номер дома/квартиры)"
                                             name="address"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Укажите адрес'
                                                }
                                             ]}>
                                             <SelectAddress allowClear />
                                          </Form.Item>

                                          <Form.Item
                                             name="addressComment"
                                             label="Дополнительно про адрес">
                                             <TextArea
                                                rows={2}
                                                placeholder="Максимум 500 символов"
                                                maxLength={500}
                                             />
                                          </Form.Item>

                                          {Object.values(leadExtraFieldsToExport)
                                             .filter(
                                                (e) =>
                                                   !['isLoadingRequired'].includes(
                                                      e.value
                                                   )
                                             )
                                             .map((e) => (
                                                <Form.Item
                                                   label={e.label}
                                                   name={e.value}
                                                   rules={[
                                                      {
                                                         required: true,
                                                         message: 'Поле пропущено'
                                                      }
                                                   ]}
                                                   key={e.value + e.label + 'form'}>
                                                   {e.type === 'boolean' ? (
                                                      <Radio.Group name={e.value}>
                                                         <Radio value={true}>
                                                            Да
                                                         </Radio>
                                                         <Radio value={false}>
                                                            Нет
                                                         </Radio>
                                                      </Radio.Group>
                                                   ) : null}

                                                   {e.type === 'integer' ? (
                                                      <Input
                                                         style={{
                                                            width: '160px'
                                                         }}
                                                         placeholder="в метрах"
                                                         type="number"
                                                      />
                                                   ) : null}
                                                </Form.Item>
                                             ))}
                                       </Col>
                                    </Row>

                                    <Row gutter={16}>
                                       <Col xs={24} span={12} xl={12}>
                                          <Form.Item
                                             name="firstName"
                                             label="Имя"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Поле пропущено'
                                                }
                                             ]}>
                                             <Input
                                                placeholder="Имя"
                                                rules={[
                                                   {
                                                      required: true,
                                                      message: 'Поле пропущено'
                                                   }
                                                ]}
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name="lastName"
                                             label="Фамилия"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Поле пропущено'
                                                }
                                             ]}>
                                             <Input placeholder="Фамилия" />
                                          </Form.Item>
                                       </Col>
                                       <Col xs={24} span={12} xl={12}>
                                          <Form.Item
                                             name="email"
                                             label="E-mail"
                                             rules={[
                                                {
                                                   type: 'email',
                                                   message: 'Не похоже на почту!'
                                                },
                                                {
                                                   required: true,
                                                   message: 'Поле пропущено'
                                                }
                                             ]}>
                                             <Input placeholder="Почта" />
                                          </Form.Item>
                                          <Form.Item
                                             name="mobileNumber"
                                             label="Телефон"
                                             rules={mobileNumberRequired}>
                                             <Input
                                                addonBefore={
                                                   <Form.Item noStyle>+7</Form.Item>
                                                }
                                                style={{
                                                   width: '100%'
                                                }}
                                                placeholder="Введите телефон"
                                             />
                                          </Form.Item>
                                       </Col>
                                       <Col xs={24} span={24} xl={24}>
                                          <Form.Item label="Даты вывоза">
                                             <Form.List name="leadTimes">
                                                {(fields, { add, remove }) => (
                                                   <>
                                                      {fields.map(
                                                         ({
                                                            key,
                                                            name,
                                                            ...restField
                                                         }) => (
                                                            <Row
                                                               gutter={24}
                                                               key={`leadTimes${key}`}>
                                                               <Col
                                                                  xs={24}
                                                                  span={24}>
                                                                  <Form.Item
                                                                     {...restField}
                                                                     name={[
                                                                        name,
                                                                        'fromTimestamp'
                                                                     ]}
                                                                     rules={[
                                                                        {
                                                                           required: true,
                                                                           message:
                                                                              'Укажите дату вывоза'
                                                                        }
                                                                     ]}>
                                                                     <DatePicker
                                                                        style={{
                                                                           width: '100%'
                                                                        }}
                                                                        format="DD-MM-YYYY"
                                                                        disabledDate={
                                                                           disabledDate
                                                                        }
                                                                        disabledTime={
                                                                           disabledDateTime
                                                                        }
                                                                     />
                                                                  </Form.Item>

                                                                  <Form.Item
                                                                     {...restField}
                                                                     name={[
                                                                        name,
                                                                        'timeArr'
                                                                     ]}
                                                                     rules={[
                                                                        {
                                                                           required: true,
                                                                           message:
                                                                              'Укажите дату вывоза'
                                                                        }
                                                                     ]}>
                                                                     <TimePicker.RangePicker
                                                                        format="HH:mm"
                                                                        minuteStep="5"
                                                                        showNow
                                                                        style={{
                                                                           width: '100%'
                                                                        }}
                                                                     />
                                                                  </Form.Item>
                                                               </Col>

                                                               <Col
                                                                  xs={24}
                                                                  span={24}>
                                                                  <Button
                                                                     style={{
                                                                        float: 'right',
                                                                        marginBottom:
                                                                           '20px'
                                                                     }}
                                                                     danger
                                                                     type="primary"
                                                                     icon={
                                                                        <MinusCircleOutlined />
                                                                     }
                                                                     onClick={() =>
                                                                        remove(name)
                                                                     }>
                                                                     Удалить время
                                                                  </Button>
                                                               </Col>
                                                            </Row>
                                                         )
                                                      )}

                                                      <Row gutter={24}>
                                                         <Col
                                                            xs={24}
                                                            span={24}
                                                            xl={24}>
                                                            <Form.Item>
                                                               <Button
                                                                  type="dashed"
                                                                  onClick={() =>
                                                                     add()
                                                                  }
                                                                  block
                                                                  icon={
                                                                     <PlusOutlined />
                                                                  }>
                                                                  Добавить
                                                               </Button>
                                                            </Form.Item>
                                                         </Col>
                                                      </Row>
                                                   </>
                                                )}
                                             </Form.List>
                                          </Form.Item>{' '}
                                       </Col>{' '}
                                       <Col
                                          xs={24}
                                          className="gutter-row"
                                          span={24}
                                          xl={24}>
                                          <Form.Item>
                                             <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={isLoading}>
                                                Создать
                                             </Button>
                                          </Form.Item>
                                       </Col>
                                       <Row gutter={[16, 24]}>
                                          {errorMessage && (
                                             <Col
                                                xs={24}
                                                className="gutter-row"
                                                span={24}>
                                                <Alert
                                                   message={errorMessage.replace(
                                                      'Error: ',
                                                      ''
                                                   )}
                                                   type="error"
                                                   style={{ marginBottom: '20px' }}
                                                   showIcon
                                                />
                                             </Col>
                                          )}
                                       </Row>
                                    </Row>
                                 </>
                              ) : null}
                           </div>
                        )}
                     </Form>{' '}
                  </div>
               </div>{' '}
            </Col>
         </Row>
      </div>
   );
};

export default CreateLeadPublic;
